import { MvCountry } from '@/lib/strapi-types/MvCountry';
import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { Strapi4ResponseMany } from '@nuxtjs/strapi/dist/runtime/types';
import { stringify } from 'qs';

interface UseFindOneCountryProps {
  filters: Record<string, unknown>;
  populate?: string[];
  fields?: string[];
}

export async function useFindOneCountry({
  filters,
  populate = [],
  fields = []
}: UseFindOneCountryProps) {
  const defaultPopulate = [''];
  const allPopulate = [...defaultPopulate, ...populate];

  const parsedQuery = stringify(
    {
      populate: allPopulate,
      filters,
      pagination: {
        start: 0,
        limit: 1
      },
      fields
    },
    { arrayFormat: 'brackets' }
  );

  const { data } = await useFetchWithHead<Strapi4ResponseMany<MvCountry['attributes']>>(
    `${useCompleteUrl(DevBackEndpoints.COUNTRIES)}?${parsedQuery}`
  );

  return { country: data.value?.data[0] };
}
